<template>
  <div style="width:100%;background:#611809;">
    <div class="container">
      <div class="banner">
      </div>
      <div class="goods-select-bar">
        <div class="goods-select-item " :class="{ active: type == '' }" @click="sorttype('')">
          综合
          <span class="split-line">/</span>
        </div>
        <div class="goods-select-item" :class="{ active: type == 'storeSaleCount' }"
          @click="sorttype('storeSaleCount')">
          销量
          <span class="split-line">/</span>
        </div>
        <div class="goods-select-item" :class="{ active: type == 'marketPrice' }" @click="sorttype('marketPrice')">
          价格
          <span class="split-line">/</span>
        </div>
        <div class="goods-select-item" @mouseleave="handleAreaMaskReset()" @click="isOpenAreaMask = true">
          {{ countyName || "产地" }}
          <span class="split-line">/</span>
          <div class="del iconfont icon-shanchu" v-show="countyName" @click.stop="handleDelArea()"></div>
          <div class="area-list" v-if="areaList.length && isOpenAreaMask">
            <div class="area-container" v-for="(item, index) in areaList" :key="index" @click.stop="
  lv1Idx = index;
showListFlag = 1;
            " v-show="showListFlag === 0">
              {{ item.areaName }}
            </div>
            <div class="area-container" v-for="(item, index) in areaList[lv1Idx].list" :key="index" @click.stop="
  lv2Idx = index;
showListFlag = 2;
            " v-show="showListFlag === 1">
              {{ item.areaName }}
            </div>
            <div class="area-container" v-for="(item, index) in areaList[lv1Idx].list[
              lv2Idx
            ].list" :key="index" v-show="showListFlag === 2" @click.stop="submitArea(item)">
              {{ item.areaName }}
            </div>
          </div>
        </div>

        <!-- <div class="search-tips">
        共找到 <span class="keyword">{{totalCount}}</span> 款符合条件的商品
      </div> -->
      </div>
      <!-- 商品列表 -->
      <ul class="goods-list" v-if="resultList.length">
        <li v-for="item in resultList" :key="item.goodsId" @click="handleToGoods(item)">
          <!-- <RouterLink to="/"> -->
          <img v-lazy="item.pictureUrl">
          <p class="name ellipsis-2">{{ item.title }}</p>
          <p class="price-bar"><span class="discount">&yen; {{ item.marketPrice }}</span> <span class="org-price">&yen;
              {{ item.yptMarketPrice }}</span> </p>
          <!-- </RouterLink> -->
        </li>
      </ul>
      <div class="slok" v-else>
        <qdzEmpty state="notResult" style="background-color: #fff; width:1240px" />
      </div>
      <qdzPagination style="background:#fff" v-if="resultList.length && refresh" :total="totalCount"
        :pageSize="pageSize" :currentPage="currentPage" @handleCheckPage="handleCheckPage" />
    </div>
  </div>
</template>
<script  setup>
import { toRefs, reactive, watch, } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { APIGetList, APIGetArea } from '@/api/search'

const route = useRoute()
const router = useRouter()
const {
  partType,
  currentPage,
  totalCount,
  totalPage,
  pageSize,
  resultList,
  refresh,
  type,
  sort, //asc升/desc降
  countyName,
  // 产地
  areaList,
  isOpenAreaMask,
  showListFlag,
  lv1Idx,
  lv2Idx,
} = toRefs(reactive({
  partType: '',
  currentPage: 1,
  totalCount: 1,
  totalPage: 1,
  pageSize: 10,
  resultList: [],
  refresh: true,
  type: '',
  sort: 'desc', //asc升/desc降
  countyName: '',

  // 产地
  areaList: [],
  isOpenAreaMask: false,
  showListFlag: 0,
  lv1Idx: 0,
  lv2Idx: 0,
}))

const handleGetResultList = (data) => {
  APIGetList({
    pageSize: 10,
    ...data
  }).then(res => {
    if (res.code === '10000') {
      resultList.value = res.data.list
      currentPage.value = res.data.currentPage
      totalCount.value = res.data.rowsCount
      refresh.value = true
    }
  })
}

// 重置区域
const handleDelArea = () => {
  countyName.value = '';
  currentPage.value = 1
  resultList.value = [];
  type.value = ''
  handleGetResultList({
    partType: route.query.partType,
    partId: route.query.id,
    type: type.value,
    countyName: countyName.value,
    currentPage: currentPage.value
  })
}
// 提交区域
function submitArea (item) {
  handleAreaMaskReset();
  countyName.value = item.areaName;
  // currentPage = 1;
  currentPage.value = 1
  resultList.value = [];
  type.value = ''
  handleGetResultList({
    partType: route.query.partType,
    partId: route.query.id,
    type: type.value,
    countyName: countyName.value,
    currentPage: currentPage.value
  })

}
// 获取区域列表
function getAreaList () {
  APIGetArea().then((res) => {
    // console.log(res);
    areaList.value = res.data;
  });
}

// 重置区域列表选择器操作
function handleAreaMaskReset () {
  showListFlag.value = 0;
  lv1Idx.value = 0;
  lv2Idx.value = 0;
  isOpenAreaMask.value = false;
}



watch(() => route.query, (value, oldVal) => {
  partType.value = value.partType
  // 关键词不同时 重新 初始化分页器 优化
  if (value.partType != oldVal?.partType) {
    refresh.value = false
  }
  handleGetResultList({
    partType: value.partType,
    partId: value.id
  })
  getAreaList()
}, { immediate: true })
//  筛选数据  
const sorttype = (v) => {
  if (v == 'storeSaleCount' || v == 'marketPrice') {
    sort.value = sort.value == 'asc' ? 'desc' : 'asc'
  } else {
    sort.value = ''
  };
  refresh.value = false
  type.value = v;
  currentPage.value = 1;
  handleGetResultList({
    partType: route.query.partType,
    partId: route.query.id,
    type: type.value,
    countyName: countyName.value,
    currentPage: currentPage.value,
    sort: sort.value
  })
}
// 切换分页
const handleCheckPage = (Page) => {
  currentPage.value = Page
  handleGetResultList({
    partType: route.query.partType,
    partId: route.query.id,
    type: 1,
    type: type.value,
    countyName: countyName.value,
    currentPage: currentPage.value
  })
}
// 去详情页
const handleToGoods = (item) => {
  // 条件判断跳转 文章详情 还是、商品详情
  let toPath = router.resolve({
    path: 'goods',
    query: {
      goodsId: item.goodsId
    }
  })
  window.open(toPath.href, '_blank')
}

</script>
<style scoped lang="less">
.banner {
  height: 485px;
  background-image: url("~@/assets/images/active-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 29px;
}

.goods-select-bar {
  display: flex;
  align-items: center;
  margin: 30px 0;

  .goods-select-item {
    color: #bec3c9;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    &.active {
      color: #fff;
    }
  }

  .split-line {
    margin: 0 19px;
    color: #bec3c9;
  }
}

.slok {
  height: 1000px;
}

.goods-list {
  display: flex;
  flex-wrap: wrap;
  height: 686px;

  li {
    .li-goods();
  }
}

// 区域
.del {
  color: #d43834;
  position: absolute;
  right: 20px;
  top: -14px;
}

.area-list {
  width: 270px;
  height: 300px;
  position: absolute;
  left: 0;
  top: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  padding: 10px;
  overflow-y: scroll;

  .area-container {
    width: fit-content;
    height: 24px;
    padding: 0 10px;
    flex-shrink: 0;
    display: inline-block;
    margin-right: 10px;
    box-sizing: border-box;
    line-height: 24px;
    background-color: #eee;
    margin-bottom: 10px;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
  }
}
</style>
